import React, { useState, useEffect, Fragment } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import AboveFooterSection from '../../components/AboveFooterSection'
import { StaticImage } from 'gatsby-plugin-image'

import Logos from '../../components/partials/Logos'

// Carousel
import monitorInactive from '../../resources/images/Homepage/carousel/monitor-inactive.svg'
import monitorAdminister from '../../resources/images/Homepage/carousel/monitor-administer.svg'
import aws_carousel_monitor from '../../resources/images/aws_page/aws-carousel-monitor.jpg'

import discoverInactive from '../../resources/images/Homepage/carousel/discover-inactive.svg'
import discoverInvestigate from '../../resources/images/Homepage/carousel/discover-investigate.svg'
import aws_carousel_discover from '../../resources/images/aws_page/aws-carousel-discover.png'

import secureInactive from '../../resources/images/Homepage/carousel/secure-inactive.svg'
import secureComply from '../../resources/images/Homepage/carousel/secure-comply.svg'
import aws_carousel_secure from '../../resources/images/aws_page/aws-carousel-secure.png'

import buildInactive from '../../resources/images/Homepage/carousel/build-inactive.svg'
import buildDeploy from '../../resources/images/Homepage/carousel/build-deploy.svg'
import aws_carousel_build from '../../resources/images/aws_page/aws-carousel-build.png'

import Spacing from '../../components/partials/Spacing'

interface StateProps {
    carouselData: CarouselProps
}

interface CarouselProps {
    activeOption: string
    hoverOption: string
    activeImageSrc: string
    activeImageAlt: string
    activeText: string
    activeLink: string
    activeLinkId: string
    linkText: string
}

// const aboutUs: React.FC<StateProps> = () => {
const aws: React.FC<StateProps> = () => {
    const data = useStaticQuery(graphql`
        query LensesAWSSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Cloud_AWS" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    const [carouselData, setCarouselData] = useState<CarouselProps>({
        activeOption: '',
        hoverOption: '',
        activeImageSrc: '',
        activeImageAlt: '',
        activeText: '',
        activeLink: '',
        activeLinkId: '',
        linkText: '',
    })

    const {
        activeOption,
        hoverOption,
        activeImageSrc,
        activeImageAlt,
        activeText,
        activeLink,
        activeLinkId,
        linkText,
    } = carouselData

    useEffect(() => {
        try {
            setCarouselData({
                ...carouselData,
                activeOption: 'monitor',
                activeImageSrc: aws_carousel_monitor,
                activeImageAlt: 'monitor and administer',
                activeText:
                    'Increase uptime & productivity with monitoring & alerting of infrastructure & flows. Self-service MSK administration and governance protected by role-based security.',
                activeLink: '/usecases/kafka-monitoring/',
                activeLinkId: 'aws-carousel-monitor-learnmore',
                linkText: 'Kafka monitoring',
            })
        } catch (err) {
            console.log(err)
        }

        // es-lint-disable-next-line
    }, [])

    const setMonitor = () => {
        setCarouselData({
            ...carouselData,
            activeOption: 'monitor',
            activeImageSrc: aws_carousel_monitor,
            activeImageAlt: 'monitor and administer',
            activeText:
                'Increase uptime & productivity with monitoring & alerting of infrastructure & flows. Self-service MSK administration and governance protected by role-based security.',
            activeLink: '/usecases/kafka-monitoring/',
            activeLinkId: 'aws-carousel-monitor-learnmore',
            linkText: 'Kafka monitoring',
        })
    }
    const monitorHover = () => {
        setCarouselData({
            ...carouselData,
            hoverOption: 'monitor',
        })
    }
    const setHoverMouseout = () => {
        setCarouselData({
            ...carouselData,
            hoverOption: '',
        })
    }

    const setDiscover = () => {
        setCarouselData({
            ...carouselData,
            activeOption: 'discover',
            activeImageSrc: aws_carousel_discover,
            activeImageAlt: 'discover and investigate',
            activeText:
                'Accelerate time to troubleshoot flows and microservices across MSK streams with visibility into data streams, consumers, partitioning & more.',
            activeLink: '/usecases/apache-kafka-data-observability/',
            activeLinkId: 'aws-carousel-discover-learnmore',
            linkText: 'Kafka data observability',
        })
    }
    const discoverHover = () => {
        setCarouselData({
            ...carouselData,
            hoverOption: 'discover',
        })
    }

    const setSecure = () => {
        setCarouselData({
            ...carouselData,
            activeOption: 'secure',
            activeImageSrc: aws_carousel_secure,
            activeImageAlt: 'secure and comply',
            activeText:
                'Reduce risk, meet compliance & increase governance with a protected and audited portal for MSK administration, data discovery, data masking and data access.',
            activeLink: '/usecases/apache-kafka-security/',
            activeLinkId: 'aws-carousel-secure-learnmore',
            linkText: 'Kafka security',
        })
    }

    const secureHover = () => {
        setCarouselData({
            ...carouselData,
            hoverOption: 'secure',
        })
    }

    const setBuild = () => {
        setCarouselData({
            ...carouselData,
            activeOption: 'build',
            activeImageSrc: aws_carousel_build,
            activeImageAlt: 'build and deploy',
            activeText:
                'Reduce skills & accelerate deployment of streaming applications & data integrations with SQL via Kubernetes and Kafka Connect. Manage flows with configuration files and manage via GitOps.',
            activeLink: '/usecases/kafka-streams/',
            activeLinkId: 'aws-carousel-build-learnmore',
            linkText: 'Kafka streams',
        })
    }

    const buildHover = () => {
        setCarouselData({
            ...carouselData,
            hoverOption: 'build',
        })
    }

    const monitor =
        hoverOption === 'monitor' || activeOption === 'monitor' ? (
            <div
                id="monitor-column"
                className="d-flex flex-wrap pb-3"
                style={{ borderLeft: '3px solid var(--lenses-red)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-top-left"
                        onMouseOver={monitorHover}
                        onClick={setMonitor}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-1 mx-auto"
                            src={monitorAdminister}
                            style={{ cursor: 'pointer' }}
                            alt="Monitor and administer"
                        />
                        <br />
                        <span className="text-1" style={{ color: '#333' }}>
                            Monitor & Administer
                        </span>
                    </a>
                </div>
            </div>
        ) : (
            <div
                id="monitor-column"
                className="d-flex flex-wrap pb-3"
                style={{ borderLeft: '3px solid #e4e3e4' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-top-left"
                        onMouseOver={monitorHover}
                        onClick={setMonitor}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-1 mx-auto"
                            src={monitorInactive}
                            style={{ cursor: 'pointer' }}
                            alt="monitor-inactive"
                        />
                        <br />
                        <span className="text-1" style={{ color: '#e4e3e4' }}>
                            Monitor & Administer
                        </span>
                    </a>
                </div>
            </div>
        )

    const discover =
        hoverOption === 'discover' || activeOption === 'discover' ? (
            <div
                id="discover-column"
                className="d-flex flex-wrap py-3"
                style={{ borderLeft: '3px solid var(--lenses-red)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-top-right"
                        onMouseOver={discoverHover}
                        onClick={setDiscover}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-2 mx-auto"
                            style={{ cursor: 'pointer' }}
                            src={discoverInvestigate}
                            alt="discover and investigate"
                        />
                        <br />
                        <span className="text-2">Discover & Investigate</span>
                    </a>
                </div>
            </div>
        ) : (
            <div
                id="discover-column"
                className="d-flex flex-wrap py-3"
                style={{ borderLeft: '3px solid var(--background-color)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-top-right"
                        onMouseOver={discoverHover}
                        onClick={setDiscover}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-2 mx-auto"
                            style={{ cursor: 'pointer' }}
                            src={discoverInactive}
                            alt="discover inactive"
                        />
                        <br />
                        <span className="text-2" style={{ color: '#e4e3e4' }}>
                            Discover & Investigate
                        </span>
                    </a>
                </div>
            </div>
        )

    const secure =
        hoverOption === 'secure' || activeOption === 'secure' ? (
            <div
                id="secure-column"
                className="d-flex flex-wrap py-3"
                style={{ borderLeft: '3px solid var(--lenses-red)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-bottom-right"
                        onMouseOver={secureHover}
                        onClick={setSecure}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-3 mx-auto"
                            style={{ cursor: 'pointer' }}
                            src={secureComply}
                            alt="secure and comply"
                        />
                        <br />
                        <span className="text-3">Secure & comply</span>
                    </a>
                </div>
            </div>
        ) : (
            <div
                id="secure-column"
                className="d-flex flex-wrap py-3"
                style={{ borderLeft: '3px solid var(--background-color)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-bottom-right"
                        onMouseOver={secureHover}
                        onClick={setSecure}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-3 mx-auto"
                            style={{ cursor: 'pointer' }}
                            src={secureInactive}
                            alt="secure inactive"
                        />
                        <br />
                        <span className="text-3" style={{ color: '#e4e3e4' }}>
                            Secure & comply
                        </span>
                    </a>
                </div>
            </div>
        )

    const build =
        hoverOption === 'build' || activeOption === 'build' ? (
            <div
                id="build-column"
                className="d-flex flex-wrap py-3"
                style={{ borderLeft: '3px solid var(--lenses-red)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-bottom-left"
                        onMouseOver={buildHover}
                        onClick={setBuild}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-4 mx-auto"
                            style={{ cursor: 'pointer' }}
                            src={buildDeploy}
                            alt="build & deploy"
                        />
                        <br />
                        <span className="text-4">Build & Deploy</span>
                    </a>
                </div>
            </div>
        ) : (
            <div
                id="build-column"
                className="d-flex flex-wrap py-3"
                style={{ borderLeft: '3px solid var(--background-color)' }}
            >
                <div className="text-center col-12 mt-4">
                    <a
                        className="carousel-bottom-left"
                        onMouseOver={buildHover}
                        onClick={setBuild}
                        onMouseOut={setHoverMouseout}
                    >
                        <img
                            className="img-fluid w-25 img-4 mx-auto"
                            style={{ cursor: 'pointer' }}
                            src={buildInactive}
                            alt="build inactive"
                        />
                        <br />
                        <span className="text-4" style={{ color: '#e4e3e4' }}>
                            Build & Deploy
                        </span>
                    </a>
                </div>
            </div>
        )

    return (
        <Fragment>
            <section className="home-banner">
                Webinar - The power of AWS MSK with DataOps
                <a
                    href="/videos/the-power-of-two-aws-msk-webinar/"
                    className="ml-1 text-white mobile-padding-right-half mobile-margin-reset"
                >
                    | Watch here &gt;
                </a>
            </section>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className="home-fixed-top"
                src="../../resources/images/homepage/hero_lens.svg"
                placeholder="blurred"
                alt="Hero Lenses"
            />
            <Layout newsletter={false} hasBanner={true}>
                {/* <!-- Header --> */}

                <section className="mobile-margin-top overflow-hidden d-lg-block">
                    <div className="container-1 hero-margin">
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="col-lg-4 col-xl-5  col-12 mobile-padding-left">
                                <p className="title breadcrumb-text">
                                    <a href="/cloud/" className="title_text">
                                        CLOUD
                                    </a>{' '}
                                    /<span className="title_text font-weight-bold">AWS</span>
                                </p>
                                <div className="mt-5">
                                    <a
                                        id="clouds-aws-hero-button-getTrial"
                                        href="//portal.lenses.io/register?version=msk"
                                    >
                                        <StaticImage
                                            className="height-80"
                                            style={{ width: '80px' }}
                                            src="../../resources/images/wizard_form/aws.png"
                                            placeholder="tracedSVG"
                                            alt="AWS"
                                        />
                                        <StaticImage
                                            className="height-80 ml-3"
                                            style={{ width: '80px' }}
                                            src="../../resources/images/aws_page/badge.jpg"
                                            placeholder="tracedSVG"
                                            alt="AWS badge"
                                        />
                                    </a>
                                    <div>
                                        <h1>
                                            Lenses for AWS Managed Streaming for Apache Kafka
                                            <br />
                                        </h1>
                                        <div className="pr-5">
                                            <h2 className="hero-description-dark pr-5  pt-1">
                                                Unlock the full potential of{' '}
                                                <b> AWS MSK with DataOps </b> to easily monitor,
                                                secure, build and run real-time applications.
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="roll-button mt-3">
                                        <a
                                            id="clouds-aws-hero-button-getTrial-2"
                                            className="text-white bg-red"
                                            href="//portal.lenses.io/register?version=msk"
                                        >
                                            <span data-hover="Start for free">Start for free</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-xl-7 pt-0 col-12 px-0">
                                <StaticImage
                                    className="img-fluid mx-auto d-block w-75 p-3"
                                    src="../../resources/images/aws_page/hero-page.svg"
                                    placeholder="tracedSVG"
                                    alt="easily monitor, secure, build and run real-time applications on AWS MSK’"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Customers logos --> */}
                <Spacing />

                <Logos width={75} />

                {/* <!-- First Image content --> */}

                <Spacing />
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-6 col-12 mobile-padding-top">
                                <h2 className="h1-like">Unleash DataOps for AWS MSK</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Amazon AWS Managed Streaming for Apache Kafka delivers a
                                        fully-managed Kafka infrastructure. Next, you’ll need a
                                        monitoring, security & governance layer to build & operate
                                        streaming flows.
                                    </p>
                                    <p>
                                        DataOps provides everyone, from developers to analysts, with
                                        a springboard to rapidly deliver new data experiences by
                                        adding secure self-service, data observability and app
                                        deployment for your AWS MSK environment.
                                    </p>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 pt-5">
                                <div className="pt-5">
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/unleasedDataopsforAWS.svg"
                                        placeholder="tracedSVG"
                                        alt="Unleash DataOps for AWS MSK with Lenses.io"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Testimonial --> */}
                <Spacing />
                <section>
                    <div className="container-1">
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-6 col-sm-7  testimonial-open-source py-3 ">
                                <StaticImage
                                    className="testimonial-open-source-company-image"
                                    src="../../resources/images/clients/vortexa/vortexa-with-color.png"
                                    placeholder="tracedSVG"
                                    alt="Vortexa"
                                />
                                <p className="testimonial-text">
                                    &ldquo;With Lenses on MSK, we can now fully utilize familiar SQL
                                    to build and deploy application logic. Enterprise security and
                                    monitoring extends our operational capabilities across all
                                    micro-services and real-time data pipelines on Kafka with
                                    Kubernetes&rdquo;{' '}
                                </p>
                                <a className="primary-text" href="/customers/vortexa/">
                                    Read More <i className="red-pointer-right"></i>
                                </a>
                                <StaticImage
                                    className="arrow-right-image"
                                    src="../../resources/images/homepage/testimonials/triangle.svg"
                                    placeholder="tracedSVG"
                                    alt="triangle"
                                />
                            </div>

                            <div className="offset-2 offset-sm-1 col-3 flex-column flex-sm-row align-items-center d-flex mobile-padding-reset">
                                <StaticImage
                                    imgClassName="quote-image"
                                    src="../../resources/images/Homepage/testimonials/maksym.png"
                                    placeholder="tracedSVG"
                                    alt="Maksym"
                                />
                                <div className="ml-3 d-flex w-100 flex-column mobile-margin-reset">
                                    <div>
                                        <p className="light-blue fw-400 mb-0"> Maksym Schipka</p>
                                    </div>
                                    <div>CTO of Vortexa</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                {/* <!-- More Images content --> */}
                {/* <!-- Senond Image --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-6">
                                <h2 className="h1-like">DataOps lets you see in the dark</h2>
                                <div className="pr-3">
                                    <p className="pr-5 mobile-margin-top-half">
                                        No more data black box. You can keep track of MSK broker
                                        health & other components including Kafka Connect with
                                        Lenses.io DataOps, which fully supports MSK Open Monitoring
                                        via Prometheus.
                                    </p>
                                    <ul className="red-checked-list mb-2">
                                        <li>
                                            <span>
                                                Monitor the performance of flows and microservices
                                                including consumer lag & partition health
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Stay productive with alerts integrated with your
                                                existing tools.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <a
                                    id="monitor-dark-learnmore"
                                    className="primary-text"
                                    href="/usecases/kafka-monitoring/"
                                >
                                    {' '}
                                    <b> More about Amazon MSK monitoring </b>
                                    <i className="red-pointer-right"></i>
                                </a>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 mobile-margin-top-half">
                                <div className="pt-3">
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/confidenceThroughVisibility.svg"
                                        placeholder="tracedSVG"
                                        alt="See in the dark with Amazon MSK monitoring"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                {/* <!-- Third Image --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-5 ">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/migrateWithConfidence.svg"
                                        placeholder="tracedSVG"
                                        alt="Run AWS MSK applications with confidence using Lenses.io DataOps"
                                    />
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6 mobile-margin-top-half ">
                                <h2 className="h1-like">
                                    Run Apache Kafka applications with confidence
                                </h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Reduce risk whilst increasing deployment consistency and
                                        velocity by managing your application landscape via
                                        configuration files across different Kafka deployments.
                                    </p>
                                    <p>
                                        Integrating your Kafka streams into your CI/CD via GitOps is
                                        made much faster using Lenses SQL (LSQL).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                {/* <!-- Fourth Image --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-6">
                                <h2 className="h1-like">Data access and debugging</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Accelerate debugging by putting streaming data into the
                                        hands of those that need it most. Use SQL to search, filter,
                                        join, aggregate & insert data on the wire.
                                    </p>
                                    <ul className="red-checked-list mb-2">
                                        <li>
                                            <span>
                                                Stay protected with authentication, audits &
                                                redaction policies
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Integrate data via popular libraries such as in
                                                Python & Go.
                                            </span>
                                        </li>
                                    </ul>
                                    <a
                                        id="explore--access-learnmore"
                                        className="primary-text"
                                        href="/usecases/apache-kafka-data-observability/"
                                    >
                                        <b>
                                            Unearth issues and discover data in AWS MSK{' '}
                                            <i className="red-pointer-right"></i>
                                        </b>
                                    </a>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 mobile-margin-top-half">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/dataAccess.svg"
                                        placeholder="tracedSVG"
                                        alt="Get access to and debug Kafka flows with DataOps over Amazon MSK"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                {/* <!-- Fifth Image --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-5">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/selfServiceWithGovernance.svg"
                                        placeholder="tracedSVG"
                                        alt="Self-service for Amazon streaming for MSK with data governance"
                                    />
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6 mobile-margin-top-half">
                                <h2 className="h1-like">
                                    Self-service with real-time data governance{' '}
                                </h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Increase productivity with your Amazon MSK cluster by
                                        empowering developers to self-serve. Manage Topics, ACLs,
                                        Quotas, Schemas & flows with full governance protected by
                                        role-based security via namespaces and auditing.
                                    </p>
                                </div>
                                <a
                                    id="aws-carousel-monitor-learnmore"
                                    className="primary-text"
                                    href="/usecases/apache-kafka-security/"
                                >
                                    <b>
                                        Learn how to secure & audit your applications on Apache
                                        Kafka <i className="red-pointer-right"></i>
                                    </b>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                {/* <!-- Sixth Image --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-6">
                                <h2 className="h1-like">Power flows to production</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <ul className="red-checked-list mb-2">
                                        <li>
                                            <span>
                                                Reduce skills required to operate Apache Kafka and
                                                build more predictable streaming flows with the
                                                power of SQL.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Create streaming applications or data integrations
                                                to popular data stores.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Deploy & scale via any Kubernetes environment such
                                                as AWS EKS or your existing Kafka Connect cluster.
                                            </span>
                                        </li>
                                    </ul>
                                    <a
                                        id="build-power-learnmore"
                                        className="primary-text"
                                        href="/usecases/kafka-streams/"
                                    >
                                        <b>
                                            How to build and deploy flows in minutes via Kubernetes{' '}
                                            <i className="red-pointer-right"></i>
                                        </b>
                                    </a>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 mobile-margin-top-half">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/powerFlowsToProduction.svg"
                                        placeholder="tracedSVG"
                                        alt="Power flows to production with DataOps for AWS MSK"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                {/* <!-- Sevent Image --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-5">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/integrationsThatFitLikeAGlove.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses.io integrations for AWS Managed Streaming for Kafka"
                                    />
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6">
                                <h2 className="mobile-margin-top h1-like">Integrations on tap</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Save months of custom development & integration and fast
                                        track your streaming data platform to production with
                                        plugins through to enterprise tools.
                                    </p>
                                    <ul className="red-checked-list mb-2">
                                        <li>
                                            <span>
                                                Manage roles & identities via LDAP or Kerberos
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Send audits out to popular SIEMs such as Splunk
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Trigger alerts with custom routing to tools such as
                                                Slack & Pagerduty.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <a
                                    id="integrations-glove-learnmore"
                                    className="primary-text"
                                    href="/architecture-and-integrations/"
                                >
                                    <b>
                                        More about integrations{' '}
                                        <i className="red-pointer-right"></i>
                                    </b>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
                {/* <!-- Carousel title--> */}
                <section>
                    <div className="container">
                        <div className="d-flex flex-wrap">
                            <div className="col-8 mx-auto">
                                <h2 className="text-center ">What can I do with MSK?</h2>
                                <div className="d-none d-md-block">
                                    <div className="mt-3 text-center" style={{ display: 'block' }}>
                                        {activeText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Carousel --> */}
                <section className="carousel">
                    {/* <!-- Tab Links --> */}
                    {/* <!-- Other than mobile --> */}
                    <div className="container d-none d-md-block">
                        <div className="d-flex flex-wrap pt-5">
                            <div className="col-md-3 col-6">
                                <div className="container-1">
                                    {monitor}
                                    {discover}
                                    {secure}
                                    {build}
                                </div>
                            </div>

                            <div className="offset-xl-1 offset-lg-0 offset-md-0"></div>
                            <div className="col-xl-8 col-lg-9 col-md-9 col-12 mt-2 px-0">
                                <div id="content-1" style={{ display: 'block' }}>
                                    <a id={activeLinkId} className="primary-text" href={activeLink}>
                                        <img
                                            className="img-fluid  img-1 mt-3"
                                            src={activeImageSrc}
                                            alt={activeImageAlt}
                                        />
                                        <div className="mt-1 text-center">
                                            {linkText} <i className="red-pointer-right"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Other than mobile --> */}

                    {/* <!-- MObile --> */}
                    <div className="container d-block d-md-none">
                        {/* <!-- Monitor --> */}
                        <div className="d-flex flex-wrap pb-3">
                            <div className="text-center col-12 mt-4">
                                <img
                                    className="img-fluid w-20 img-1"
                                    src={monitorAdminister}
                                    alt="monitor & administer"
                                />
                                <br />
                                <span style={{ color: '#333' }}>Monitor & Administer</span>
                                <div className="my-4">
                                    Increase uptime & productivity with monitoring & alerting on
                                    infrastructure health and flows. Lenses provides self-service
                                    MSK administration and governance protected by role-based
                                    security.
                                </div>
                            </div>
                        </div>

                        <div>
                            <img className="img-fluid" src={aws_carousel_monitor} alt="Monitor" />
                            <div className="text-center mt-4">
                                <a
                                    id="aws-carousel-monitor-learnmore"
                                    className=" primary-text"
                                    href="/usecases/kafka-monitoring/"
                                >
                                    Kafka monitoring <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!-- Monitor END --> */}
                        {/* <!-- Discover --> */}
                        <div className="d-flex flex-wrap pt-5 pb-3">
                            <div className="text-center col-12 mt-4">
                                <img
                                    className="img-fluid w-20"
                                    src={discoverInvestigate}
                                    alt="discover and investigate"
                                />
                                <br />
                                <span style={{ color: '#333' }}>Discover & Investigate</span>
                                <div className="my-4">
                                    Accelerated time to troubleshoot flows and microservices across
                                    MSK streams with visibility into data streams, consumers,
                                    partitioning & more.
                                </div>
                            </div>
                        </div>

                        <div>
                            <img className="img-fluid" src={aws_carousel_discover} alt="Discover" />
                            <div className="mt-4 text-center">
                                <a
                                    id="aws-carousel-explore-learnmore"
                                    className="primary-text"
                                    href="/usecases/apache-kafka-data-observability/"
                                >
                                    Kafka data observability <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!-- Discover END --> */}
                        {/* <!-- Secure --> */}
                        <div className="d-flex flex-wrap pt-5 pb-3">
                            <div className="text-center col-12 mt-4">
                                <img
                                    className="img-fluid w-20"
                                    src={secureComply}
                                    alt="secure and comply"
                                />
                                <br />
                                <span style={{ color: '#333' }}>Secure & comply</span>
                                <div className="my-4">
                                    Reduce risk, meet compliance & increase governance with a
                                    protected and audited portal for MSK administration, data
                                    discovery, data masking and data access.
                                </div>
                            </div>
                        </div>

                        <div>
                            <img className="img-fluid" src={aws_carousel_secure} alt="Secure" />

                            <div className="mt-4 text-center">
                                <a
                                    id="aws-carousel-secure-learnmore"
                                    className="primary-text"
                                    href="/usecases/apache-kafka-security/"
                                >
                                    Kafka security <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!-- Secure END --> */}
                        {/* <!-- Build --> */}
                        <div className="d-flex flex-wrap pt-5 pb-3">
                            <div className="text-center col-12 mt-4">
                                <img
                                    className="img-fluid w-20"
                                    src={buildDeploy}
                                    alt="build and deploy"
                                />
                                <br />
                                <span style={{ color: '#333' }}>Build & Deploy</span>
                                <div className="my-4">
                                    Reduce skills & accelerate deployment of streaming applications
                                    & data integrations with SQL via Kubernetes and Kafka Connect.
                                    Manage flows with configuration files and manage via GitOps.
                                </div>
                            </div>
                        </div>

                        <div>
                            <img className="img-fluid" src={aws_carousel_build} alt="Build" />
                            <div className="mt-4 text-center">
                                <a
                                    id="aws-carousel-build-learnmore"
                                    className="primary-text"
                                    href="/usecases/kafka-streams/"
                                >
                                    Kafka streams <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!-- Build END --> */}
                    </div>
                    {/* <!-- MOBILE --> */}
                </section>

                <AboveFooterSection
                    text={'Want to quickly try Lenses?'}
                    ctaText={"Let's go"}
                    ctaLink="/start/"
                />
            </Layout>
        </Fragment>
    )
}

export default aws
